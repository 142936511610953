import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Background`}</h2>
    <p>{`Communication is based on words. If we want to create a digital product that sounds human and relates to the people using it, we need communicative words. Words, in this context, complement our design and convey messages.`}</p>
    <h2>{`Our Communication`}</h2>
    <p>{`With every copy we create and publish, we aim to:`}</p>
    <div {...{
      "style": {
        "display": "flex",
        "background": "white",
        "justifyContent": "space-evenly",
        "flexWrap": "wrap",
        "rowGap": "24px"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/comunication-1.svg",
          "alt": "our communication principles"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/comunication-2.svg",
          "alt": "our communication principles"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/comunication-3.svg",
          "alt": "our communication principles"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/comunication-4.svg",
          "alt": "our communication principles"
        }}></img>{`
  `}</div>
    </div>
    <h2>{`Respect`}</h2>
    <p>{`Treat users with the respect they deserve. Put yourself in their shoes, and don’t patronize them. Remember that they have other things to do. Be considerate, inclusive, and communicate with them.`}</p>
    <h2>{`Guide`}</h2>
    <p>{`Think of yourself as a reliable companion for users. Communicate with them in a helpful way. Work to understand the complex problems users face, then find clear and approachable solutions to those problems.`}</p>
    <h2>{`Encourage`}</h2>
    <p>{`Users need to get things done. Content should be written and structured to help the reader understand and take the most important actions. Always prioritize the most important information and task. Our copy should give people confidence that they’re capable of achieving their goals.`}</p>
    <h2>{`Efficient`}</h2>
    <p>{`We should help users achieve their goals quickly and accurately. Pay attention to the sentence structure, and make sure the sentences can make users understand the information faster.`}</p>
    <div className="divi" />
    <h2>{`Communication Rules`}</h2>
    <p>{`In order to achieve those goals, we make sure our content is:`}</p>
    <div {...{
      "style": {
        "display": "flex",
        "background": "white",
        "justifyContent": "space-evenly",
        "flexWrap": "wrap",
        "rowGap": "24px"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/rules-1.svg",
          "alt": "communication rules"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/rules-2.svg",
          "alt": "communication rules"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "background": "#EEF0F7",
          "borderRadius": "16px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/writing-principles/rules-3.svg",
          "alt": "communication rules"
        }}></img>{`
  `}</div>
    </div>
    <h2>{`Concise`}</h2>
    <p>{`Space efficient, short, and straightforward to inform users what are they currently facing and what are they expected to do. They will go through the whole text fast without pausing to think and collect the main points. It will take less cognitive effort, lower the memory burden, avoid misunderstanding and improve the task success of our users.`}</p>
    <h2>{`Empathize`}</h2>
    <p>{`Write like a human. Adapt your tone depending on who you’re writing to and give more attention to the context. Words that we use in our content should reduce the alienation between people and machines. Write in a spoken language and use contractions to make the sentence flow more naturally.`}</p>
    <h2>{`Consistent`}</h2>
    <p>{`To be able to get your messages across clearly, your copy needs to be consistent. Otherwise, users might feel confused or frustrated. Write copy according to guidelines, mechanics, tone of voice, and what the design system defines to make all the copy uniform throughout the entire product.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      